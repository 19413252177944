import { render, staticRenderFns } from "./GuestBox.vue?vue&type=template&id=5ffd45ce&scoped=true&"
import script from "./GuestBox.vue?vue&type=script&lang=js&"
export * from "./GuestBox.vue?vue&type=script&lang=js&"
import style0 from "./GuestBox.vue?vue&type=style&index=0&id=5ffd45ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ffd45ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ffd45ce')) {
      api.createRecord('5ffd45ce', component.options)
    } else {
      api.reload('5ffd45ce', component.options)
    }
    module.hot.accept("./GuestBox.vue?vue&type=template&id=5ffd45ce&scoped=true&", function () {
      api.rerender('5ffd45ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8CUW6TUE69G2S/JS/comp/GuestBox.vue"
export default component.exports